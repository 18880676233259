import { createContext, useEffect, useState } from 'react';

import { auth, signIn } from 'feature/firebase';

/**
 * Top-level helper for Session context.
 */
export function useSession(): Object {
  const [session, setSession] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged(
      (user) => setSession(
        user ?
          {id: user.uid | 0} :
          false
      )
    );
  }, []);

  return session;
}

/**
 * Returns `true` if passed session state isn't determined yet.
 */
export function isPending(session: ?Object): bool {
  return session === null;
}

export { signIn };

const SessionContext = createContext();

SessionContext.displayName = 'SessionContext';

export default SessionContext;
