import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';

import Access from 'component/Access';
import Loading from 'component/Loading';

import Auth from 'component/page/Auth';

const Map = lazy(() => import('component/page/Map'));

function Router() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Access guestPath="/" userPath="/map">
          <Switch>
            <Route exact path="/" component={Auth} />
            <Route exact path="/map" component={Map} />
            <Route default>
              <Redirect to="/" />
            </Route>
          </Switch>
        </Access>
      </Suspense>
    </BrowserRouter>
  );
}

export default Router;
