import React, { Component } from 'react';

/**
 * Default error boundary useful for wrapping other components.
*/
export default class Boundary extends Component {

  constructor() {
    super();

    this.state = {
      error: null
    };
  }

  componentDidCatch(error) {
    this.setState({error});
  }

  render() {
    // const error = this.state.error;

    return this.component ?
      <this.component {...this.props} /> :
      this.props.children;
  }
}
