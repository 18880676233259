import React from 'react';

import SessionContext, { useSession } from "context/Session";

import Boundary from "component/Boundary";
import Router from "component/Router";

export function App() {
  const session = useSession();

  return (
    <React.StrictMode>
      <SessionContext.Provider value={session}>
        <Router />
      </SessionContext.Provider>
    </React.StrictMode>
  );
}

// WORKAROUND: currenly only class component can be an error boundary.
export default class AppBoundary extends Boundary {
  component = App
}
