import React, { useContext } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import SessionContext, { isPending } from 'context/Session';

/**
 * Prevents rendering for non-authorized users, based on SessionContext value.
 */
export function Access({guestPath, userPath, children}) {
  const location = useLocation();
  const session = useContext(SessionContext);

  // NOTE support for Suspense
  if (isPending(session)) {
    throw Promise.resolve();
  }

  return session ?
    location.pathname === guestPath ?
      location.hash || location.search ?
        children :
        <Redirect to={userPath} /> :
      children :
    location.pathname === guestPath ?
      children :
      <Redirect to={guestPath} />;
}

export default Access;
