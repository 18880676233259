import React from 'react';
import ReactDOM from 'react-dom';

import App from 'component/App';

import 'style/index.css';

ReactDOM.render(
  React.createElement(App),
  document.getElementById('root')
);

// NOTE pidrila ebanaya.
setTimeout(() => {
  window.console.log('%c\t\t', 'font-size: 400px; background: url(' + window.document.location.origin + '/console.jpg) no-repeat; background-size: contain;');
}, 1000);
