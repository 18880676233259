import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import SessionContext, { signIn } from 'context/Session';

const TELEGRAM_URL = 'https://t.me/' + process.env.REACT_APP_TELEGRAM_BOT;

function Auth() {
  const history = useHistory();
  const location = useLocation();
  const session = useContext(SessionContext);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (location.hash) {
      // TODO check session?
    } else if (location.search) {
      signIn(location.search).then(
        () => history.replace('/'),
        setError
      );
    }
  }, [history, location]);

  // TODO not offer the bot without a hash.
  const url = location.hash ?
    TELEGRAM_URL + '?start=' + location.hash.substr(1) :
    TELEGRAM_URL;

  return location.search ?
    null :
    <div className="auth-page">
      <a className="telegram" href={url} rel="noopener noreferrer">
        <img src="/telegram.svg" width="128" height="128" title="Log in with Telegram" alt="" />
      </a>
      {error}
    </div>;
}

export default Auth;
