import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';

import config from 'firebase.json';

firebase.initializeApp(config);

export const auth = firebase.auth();
export const database = firebase.database();
export const functions = firebase.functions();

const REALTIME_THROTTLE = 500; // ms

window.document.location.hostname === 'localhost' &&
  functions.useEmulator('localhost', 5001);

auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export function watchUsers(listener: Function): Function {
  const users = {};
  const updateUser = (id, data, list) => {
    const known = !!users[id];
    users[id] = users[id] || {};
    if (data) {
      Object.assign(users[id], data);
    }
    if (list) {
      // NOTE sparse array isn't iterable.
      Object.assign(users[id], {
        latitude: list[0],
        longitude: list[1],
        accuracy: list[2],
        heading: list[5],
        timestamp: list[10],
        status: !!list[11],
      });
    }

    return known;
  };
  const onChild = (data) => {
    const id = data.key | 0;
    if (updateUser(id, null, data.val())) {
      listener(null, {[id]: users[id]});
    } else {
      // NOTE get data about not yet known user.
      // TODO accumulate IDs to reduce number of invocations.
      callFunction('users', {ids: [id]}).then((list) => {
        list.forEach(
          (user) => updateUser(user.id, user)
        );
        listener(null, {[id]: users[id]});
      });
    }
  };

  // TODO fetch FS & RTDB in parallel.
  callFunction('users').then((list) => {
    list.forEach(
      (user) => updateUser(user.id, user)
    );

    database.ref('status').once('value', (snapshot) => {
      const statuses = snapshot.val();
      for (let key in statuses) {
        const id = key | 0;
        updateUser(id, null, statuses[key]);
      }
      listener(null, users);

      database.ref('status').on('child_added', onChild);
      database.ref('status').on('child_changed', onChild);
    }, console.error);
  }, console.error);

  return () => {
    database.ref('status').off('child_added', onChild);
    database.ref('status').off('child_changed', onChild);
  };
}

export function setRealtimeLocation(uid: number, latitude: number, longitude: number, altitude: number, heading: number, accuracy: number, timestamp: number): void {
  const now = Date.now();
  if (now - setRealtimeLocation.now < REALTIME_THROTTLE) {
    return Promise.resolve();
  }
  setRealtimeLocation.now = now;

  return database.ref('status').child(uid).update({
    0: latitude,
    1: longitude,
    2: (accuracy | 0) || null,
    3: (altitude | 0) || null,
    4: null, // TODO altitude accuracy
    5: (heading | 0) || null,
    6: null, // TODO heading accuracy
    7: null, // TODO speed
    8: null, // TODO speed accuracy
    // 9: battery
    10: timestamp,
    // 11: status
  });
}

export function setRealtimeStatus(uid: number, status: bool): void {
  return database.ref('status').child(uid).child(11).set(status ? 1 : 0);
}

export function signIn(search: string): Promise<Object> {
  return fetch('/auth', {
    body: new URLSearchParams(search),
    method: 'POST',
    referrer: '',
  }).then((response) => {
    if (response.status === 403) {
      return Promise.reject(new Error('not authenticated'));
    }

    return response.ok ?
      response.json() :
      Promise.reject(new Error(response.statusText))
  }).then(
    ({token, ...data}) => auth.signInWithCustomToken(token)
  );
}

export function callFunction(name: string, data: ?Object) {
  return functions.httpsCallable(name)(data).then(
    ({data}) => data
  );
}

export function tryParseJson(value: string): any {
  try {
    return JSON.parse(value);
  } catch (thrown) {
    return undefined;
  }
}
